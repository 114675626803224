<template>
  <el-container style="display: flex; flex-direction: column;  justify-content: center; border: 2px solid #ddd; min-height: 100vh;">

    <!-- 用友logo -->
    <div style="text-align: center; margin-bottom: 20px;">
      <img src="../../assets/logo.png" alt="用友Logo" style="max-width: 200px;">
    </div>

    <!-- 页面标题 -->
    <h3 style="text-align: center;">电子发票开具</h3>

    <!-- 表单内容 -->
    <el-form :model="form">
      <!-- 发票类型选择 -->
      <div style="text-align: center;margin-top: 20px;margin-bottom: 20px">
        <el-radio-group v-model="form.billingType">
          <el-radio label="企业单位">企业单位</el-radio>
          <el-radio label="机关事业单位">机关事业单位</el-radio>
          <el-radio label="个人/其他">个人/其他</el-radio>
        </el-radio-group>
      </div>

      <!-- 个人/其他类型的表单项 -->
      <template v-if="form.billingType === '个人/其他'">
        <el-form-item label-width="120px">
          <template #label>
            <span class="required-label">购方名称</span>
          </template>
          <el-input v-model="form.buyName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="120px">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label-width="120px">
          <template #label>
            <span class="required-label">邮箱</span>
          </template>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
      </template>

      <!-- 企业单位类型的表单项 -->
      <template v-if="form.billingType === '企业单位'">
        <el-form-item label-width="120px">
          <template #label>
            <span class="required-label">购方名称</span>
          </template>
          <el-input v-model="form.buyName"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" label-width="120px">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label-width="120px">
          <template #label>
            <span class="required-label">邮箱</span>
          </template>
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label-width="120px">
          <template #label>
            <span class="required-label">纳税人识别号</span>
          </template>
          <el-input v-model="form.taxpayerNumber"></el-input>
        </el-form-item>
        <el-form-item label="地址、电话" label-width="120px">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="开户行及账号" label-width="120px">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
      </template>

      <!-- 机关事业单位类型的表单项 -->
      <template v-if="form.billingType === '机关事业单位'">
        <el-form-item label="单位名称" label-width="120px">
          <el-input v-model="form.buyName"></el-input>
        </el-form-item>
        <el-form-item label="单位代码" label-width="120px">
          <el-input v-model="form.taxpayerNumber"></el-input>
        </el-form-item>
      </template>

      <!-- 提交按钮 -->
      <el-form-item style="text-align: center;">
        <el-button type="primary" @click="submitForm" class="submit-button">提交</el-button>
      </el-form-item>
    </el-form>

    <div style="color: red; text-align: center">
      如提交后未收到短信，可再次扫码查看发票开具状态。
    </div>

    <!-- 订单信息显示 -->
    <div style="margin-top: 20px; text-align: left;">
      <p>订单号: {{ form.id }}</p>
      <p>订单日期: {{ form.orderDate }}</p>
    </div>

    <!-- 错误信息显示 -->
    <el-alert v-if="errorMessage" :title="errorMessage" type="error" show-icon style="margin-top: 20px; text-align: center">
    </el-alert>
  </el-container>


</template>

<script>
import axios from "@/js/axios";
export default {
  data() {
    return {
      form: {
        id: "", //订单号
        orderDate: "", //订单日期
        billingType: "企业单位", // 默认选择
        buyName: "",
        phone: "",
        email: "",
        taxpayerNumber: "",
        address: "",
        account: "",
        price: "",
      },
      errorMessage: "",
      isBLUE:""
    };
  },
  methods: {
    fetchOrderInfo() {

    },
    validateForm() {
      let valid = true;
      // 根据 billingType 设置不同的值
      if (this.form.billingType === '个人/其他') {
        if (!this.form.buyName) {
          this.errorMessage = '请填写购方名称';
          valid = false;
        } else if (!this.form.email) {
          this.errorMessage = '请填写邮箱';
          valid = false;
        }
      } else if (this.form.billingType === '企业单位') {
        if (!this.form.buyName) {
          this.errorMessage = '请填写购方名称';
          valid = false;
        } else if (!this.form.email) {
          this.errorMessage = '请填写邮箱';
          valid = false;
        } else if (!this.form.taxpayerNumber) {
          this.errorMessage = '请填写纳税人识别号';
          valid = false;
        }
      } else if (this.form.billingType === '机关事业单位') {
        if (!this.form.buyName) {
          this.errorMessage = '请填写单位名称';
          valid = false;
        } else if (!this.form.taxpayerNumber) {
          this.errorMessage = '请填写单位代码';
          valid = false;
        }
      }
      return valid;
    },
    submitForm(event) {
      // 阻止表单的默认提交行为（如果有）
      if (event) event.preventDefault();

      // 检查表单是否验证通过
      if (this.validateForm()) {
        this.errorMessage = '';

        // 根据 billingType 设置不同的值
        if (this.form.billingType === '企业单位') {
          this.form.billingType = "1";
        } else if (this.form.billingType === '机关事业单位') {
          this.form.billingType = "2";
        } else if (this.form.billingType === '个人/其他') {
          this.form.billingType = "3";
        }

        // 打印调试信息，确保 invoiceHeader 构建正确
        console.log('Invoice Header:', JSON.stringify(this.form));
        // console.log('Invoice Header is FormData:',invoiceHeader instanceof FormData)
        // alert(this.form)
        // 使用 axios.post 向后端接口发送数据
        axios.post("https://kp.7-11bj.com/api/invoiceHeader/save",JSON.stringify(this.form)).then(response => {
          // 成功处理响应
          this.$message.success('提交成功');
          this.checkKpInfoStatus();
        }).catch(error => {
              // 错误处理
              if (error.response) {
                // 服务器有响应，但状态码不在 2xx 范围
                this.$message.error('提交失败: ' + (error.response.data.message || '服务器错误'));
              } else if (error.request) {
                // 请求已发出但没有收到响应
                this.$message.error('提交失败: 没有收到服务器响应');
              } else {
                // 其他错误，如配置问题
                this.$message.error('提交失败: ' + error.message);
              }
            });
      } else {
        // 表单验证失败的提示信息
        this.$message.error(this.errorMessage);
      }
    },
    checkKpInfoStatus() {
      // 调用新的接口，假设接口地址为 "/taxCloud/queryKpInfoByDDH"
      axios.get(`https://kp.7-11bj.com/taxCloud/queryKpInfoByDDH?DDH=${this.form.id}`)
          .then(response => {
            const result = response.data;
            // 检查接口返回的数据，确保包含 "isBLUE" 和 "price"
            if (result && result.data) {
              const isBLUE = result.data.isBLUE;  // 获取 isBLUE 值
              const price = result.data.price;    // 获取 price 值
              if (isBLUE === 1) {
                // 如果 isBLUE 为 true，设置价格并跳转到 success 页面
                this.form.price = price;
                window.location.href = `/success?orderId=${encodeURIComponent(this.form.id)}&buyName=${encodeURIComponent(this.form.buyName)}&price=${encodeURIComponent(this.form.price)}`;
              } else {
                // 如果 isBLUE 为 0，跳转到 wait 页面
                window.location.href = '/wait';
              }
            }else {
              // 如果未能正确的查询到数据，跳转到 wait 页面
              window.location.href = '/wait';
            }
          })
          .catch(error => {
            // 处理查询接口时的错误
            this.$message.error('查找发票状态失败: ' + error.message);
          });
    }
  },
  mounted() {
    const id = this.$route.query.orderId;
    const orderDate = this.$route.query.DDRQ;
    // const price = this.$route.query.price;
    // 将获取的值赋予 data 中的 form
    if (id && orderDate) {
      this.form.id = id;
      this.form.orderDate = orderDate;
    } else {
      // 如果没有查询参数，可以跳转到错误页面或处理其他逻辑
      this.errorMessage = "订单号或订单日期缺失";
    }
    this.fetchOrderInfo(); // 组件挂载时获取订单信息
  }
}
</script>

<style scoped>
.el-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.el-form-item {
  margin-bottom: 20px;
}

.el-form-item label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
.el-input {
  width: 80%;
}

.submit-button {
  width: 100px;
  height: 40px;
  font-size: 16px;
  margin: 0 auto;
  display: block;
}

.required-label::before {
  content: '*';
  color: red;
  margin-right: 4px;
}
</style>
