<template>
  <el-container style="display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 100vh; border: 2px solid #ddd; padding: 20px;">

    <!-- Aisino logo -->
    <div style="text-align: center; margin-bottom: 20px;">
      <img src="../../assets/logo.png" alt="Aisino Logo" style="max-width: 150px;">
    </div>

    <!-- 成功消息 -->
    <el-alert
        type="success"
        title="您的开票申请已受理"
        show-icon
        center
        style="margin-bottom: 20px; width: 80%;">
    </el-alert>

    <!-- 发票详情 -->
    <div style="text-align: center; margin-bottom: 20px;">
      <p>发票抬头：{{ invoiceInfo.title }}</p>
      <p>发票金额：{{ invoiceInfo.amount }}元</p>
    </div>

    <!-- 查看发票按钮 -->
    <el-button
        type="primary"
        style="margin-bottom: 20px;"
        @click="viewInvoice">查看发票
    </el-button>

    <!-- PDF 或 图片展示 -->
    <div v-if="pdf" style="width: 80%; height: 600px; margin-top: 20px;">
      <iframe v-if="isPDF" :src="pdf" width="100%" height="100%" style="border: none;"></iframe>

      <!-- 显示Base64图片 -->
      <img v-else :src="'data:image/jpeg;base64,' + pdf" width="100%" height="100%" alt="电子发票" />
    </div>

  </el-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      orderId: '', // 订单ID
      invoiceInfo: {
        title: '', // 发票抬头
        amount: '' // 发票金额
      },
      pdf: "", // 用于存储PDF或图像的Base64数据
      isPDF: true // 用于判断是PDF还是图片
    };
  },
  methods: {
    fetchInvoiceInfo() {
      // 从URL参数获取发票信息
      const buyName = this.$route.query.buyName || '未知客户';
      const price = this.$route.query.price || '0.00';
      this.orderId = this.$route.query.orderId;
      this.invoiceInfo.title = decodeURIComponent(buyName);
      this.invoiceInfo.amount = decodeURIComponent(price);
    },
    async viewInvoice() {
      const vm = this;
      try {
        // 调用后端接口获取Base64编码的发票数据
        const response = await axios.get(`https://kp.7-11bj.com/taxCloud/queryKpInfoByDDH?DDH=${vm.orderId}`);
        const results = response.data || {};

        if (results.data.pdf) {
          const base64Data = results.data.pdf;

          // 检查Base64字符串的前缀，判断文件类型
          if (base64Data.startsWith("JVBERi0")) {
            // 如果是PDF
            vm.isPDF = true;
            vm.pdf = `data:application/pdf;base64,${base64Data}`;
          } else if (base64Data.startsWith("/9j/")) {
            // 如果是JPEG图片
            vm.isPDF = false;
            vm.pdf = base64Data; // 直接使用图片的Base64数据
          } else {
            alert('文件格式无法识别');
          }

        } else {
          alert('未找到发票文件');
        }
      } catch (error) {
        console.error('获取发票失败:', error);
      }
    }
  },
  mounted() {
    this.fetchInvoiceInfo(); // 组件挂载时获取发票信息
  }
};
</script>

<style scoped>
.el-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.el-button {
  width: 120px;
  height: 40px;
  font-size: 16px;
}

.el-alert {
  width: 100%;
  max-width: 500px;
}

iframe, img {
  border: none;
}
</style>
