<template>
  <el-container style="display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 100vh; border: 2px solid #ddd; padding: 20px;">

    <!-- Aisino logo -->
    <div style="text-align: center; margin-bottom: 20px;">
      <img src="../../assets/logo.png" alt="Aisino Logo" style="max-width: 150px;">
    </div>

    <!-- 提示信息 -->
    <div style="text-align: center; color: #333; margin-bottom: 20px; font-size: 18px;">
      <p>订单已经超过48小时, 请联系</p>
      <p>客服开发发票...</p>
    </div>

    <!-- 客服电话 -->
    <div style="text-align: center; color: #555; font-size: 16px;">
      <p>客服电话: XXXXXXXXXX</p>
    </div>

  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 可在这里定义任何需要的数据
    };
  },
  methods: {
    // 可在这里定义任何需要的方法
  },
  mounted() {
    // 页面加载后需要执行的操作
  }
};
</script>

<style scoped>
.el-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.el-button {
  width: 120px;
  height: 40px;
  font-size: 16px;
}

.el-alert {
  width: 100%;
  max-width: 500px;
}
</style>
