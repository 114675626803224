<template>
  <el-container style="display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 100vh; border: 2px solid #ddd; padding: 20px;">
    <div style="text-align: center; margin-bottom: 20px;">
      <img src="../../assets/logo.png" alt="Aisino Logo" style="max-width: 150px;">
    </div>
    <div style="text-align: center; color: green;">
      已成功提交开票请求
    </div>
    <div style="text-align: center; color: green;">
      开票进程中，请等待
    </div>
  </el-container>

</template>

<script>
export default {
  name: "WaitView.vue"
}
</script>

<style scoped>

</style>