import { createRouter, createWebHistory } from 'vue-router'
import InvoiceView from "@/views/qrfront/InvoiceView.vue";
import InvoiceSuccessView from "@/views/qrfront/Success.vue";
import ContactAdminView from "@/views/qrfront/ContactAdminView.vue";
import HomeView from "@/views/qrfront/HomeView.vue";
import WaitView from "@/views/qrfront/WaitView.vue";
const routes = [
  {
    path: '/doInvoice',
    name: 'InvoiceView',
    component: InvoiceView
  },
  {
    path: '/success',
    name: 'InvoiceSuccessView',
    component: InvoiceSuccessView
  },
  {
    path: '/contactAdmin',
    name: 'contactAdminView',
    component: ContactAdminView
  },
  {
    path: '/invoice',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/wait',
    name: 'Wait',
    component: WaitView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
